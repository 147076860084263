/*
 * @Description: 所有接口
 */
import http, { HttpResponse } from '../request'

/* 请求参数类型 */
// 操作手册-搜索
interface handbookSearchQuery {
  keyword: string
}
// 热门问题列表
interface problemListQuery {
  paginate: number
  page: number
  keyword?: string
}
// 意见反馈
interface opinionSaveQuery {
  title: string
  name: string
  mobile: string
  content: string
  pic: string[]
}
interface queryId {
  id: number
}

/* 接口定义 */
export class indexApi {
  /* 操作手册 */
  // 列表
  static handbookList (data: any): Promise<HttpResponse> {
    return http({
      url: '/api/client/handbook/index',
      data
    })
  }

  // 列表-搜索
  static handbookSearchList (data: handbookSearchQuery): Promise<HttpResponse> {
    return http({
      url: '/api/client/handbook/search_index',
      data
    })
  }

  // 详情
  static handbookInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/client/handbook/info',
      data
    })
  }

  /* 热门问题 */
  // 列表
  static problemList (data: problemListQuery): Promise<HttpResponse> {
    return http({
      url: '/api/client/problem/index',
      data
    })
  }

  // 详情
  static problemInfo (data: queryId): Promise<HttpResponse> {
    return http({
      url: '/api/client/problem/info',
      data
    })
  }

  /* 意见反馈 */
  static opinionSave (data: opinionSaveQuery): Promise<HttpResponse> {
    return http({
      url: '/api/client/opinion/save',
      data
    })
  }
}
